import React from "react";
import "./term.style.css";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import Button from "antd/lib/button";
import Checkbox from "antd/lib/checkbox";
import { useTranslation } from "react-i18next";
import {
  TERM_LINK,
  TERM_LOC_LINK,
  TERM_PRIVACY_LINK,
} from "../../../common/consts.common";

export type Term = {
  checkedTerm: boolean;
  checkedPrivacy: boolean;
  checkedMsg: boolean;
  checkedLate: boolean;
  checkedLocation: boolean;
};

interface Props {
  onNext: (term: Term) => void;
}
const SignupTermView = ({ onNext }: Props) => {
  const { t } = useTranslation(["signup"]);
  const [checkedTerm, setCheckedTerm] = React.useState(false);
  const [checkedPrivacy, setCheckedPrivacy] = React.useState(false);
  const [checkedMsg, setCheckedMsg] = React.useState(false);
  const [checkedLate, setCheckedLate] = React.useState(false);
  const [checkedLocation, setCheckedLocation] = React.useState(false);

  const handleSubmit = () => {
    onNext({
      checkedLocation,
      checkedMsg,
      checkedLate,
      checkedPrivacy,
      checkedTerm,
    });
  };

  const onChangeTerm = (e: any) => {
    setCheckedTerm(e.target.checked);
  };

  const onChangeLocation = (e: any) => {
    setCheckedLocation(e.target.checked);
  };

  const onChangePrivacy = (e: any) => {
    setCheckedPrivacy(e.target.checked);
  };

  const onChangeMsg = (e: any) => {
    setCheckedMsg(e.target.checked);
  };

  const onChangeLate = (e: any) => {
    setCheckedLate(e.target.checked);
  };

  const onCheckAllChange = (e: any) => {
    setCheckedLocation(e.target.checked);
    setCheckedMsg(e.target.checked);
    setCheckedLate(e.target.checked);
    setCheckedPrivacy(e.target.checked);
    setCheckedTerm(e.target.checked);
  };

  return (
    <LeftTitledTemplate
      header={t("term.header")}
      title={t("term.title")}
      subtitle={t("term.subtitle")}
    >
      <div className={"term-form"}>
        <Checkbox
          data-testid={"term-all"}
          className={"term-all"}
          data-cy={"checkAll"}
          checked={
            checkedLate &&
            checkedMsg &&
            checkedLocation &&
            checkedPrivacy &&
            checkedTerm
          }
          onChange={onCheckAllChange}
        >
          <div className={"term-all-txt"}>{t("term.check.all")}</div>
        </Checkbox>
        <Checkbox
          data-testid={"term-service"}
          className={"term-check"}
          checked={checkedTerm}
          data-cy={"changeTerm"}
          onChange={onChangeTerm}
        >
          <div className={"term-check-row"}>
            {t("term.check.service")}
            <Button
              type={"text"}
              data-testid={"term-1"}
              className={"term-check-btn"}
              data-cy={"openTerm"}
              onClick={() => window.open(TERM_LINK)}
            >
              {t("term.see.term")}
            </Button>
          </div>
        </Checkbox>
        <Checkbox
          className={"term-check"}
          checked={checkedPrivacy}
          data-cy={"privacyCheck"}
          onChange={onChangePrivacy}
        >
          <div className={"term-check-row"}>
            {t("term.check.privacy")}
            <Button
              type={"text"}
              className={"term-check-btn"}
              data-cy={"termPrivacy"}
              onClick={() => window.open(TERM_PRIVACY_LINK)}
            >
              {t("term.see.term")}
            </Button>
          </div>
        </Checkbox>
        <Checkbox
          className={"term-check"}
          data-cy={"locationCheck"}
          checked={checkedLocation}
          onChange={onChangeLocation}
        >
          <div className={"term-check-row"}>
            {t("term.check.location")}
            <Button
              type={"text"}
              className={"term-check-btn"}
              onClick={() => window.open(TERM_LOC_LINK)}
            >
              {t("term.see.term")}
            </Button>
          </div>
        </Checkbox>
        <Checkbox
          className={"term-check"}
          checked={checkedMsg}
          onChange={onChangeMsg}
        >
          {t("term.check.msg")}
        </Checkbox>
        <Checkbox
          className={"term-check"}
          checked={checkedLate}
          onChange={onChangeLate}
        >
          {t("term.check.late")}
        </Checkbox>
      </div>
      <Button
        data-cy={"buttonNext"}
        data-testid={"button-next"}
        type={"primary"}
        className={"bottom-btn"}
        onClick={handleSubmit}
      >
        {t("term.btn")}
      </Button>
    </LeftTitledTemplate>
  );
};

export default SignupTermView;
