import React, { useEffect, useMemo, useRef, useState } from "react";
import "./containerItem.style.css";
import Button from "antd/lib/button";
import { useTranslation } from "react-i18next";
import { ContainerType, ShippingBoxStatus } from "../../../api/apiTypes";
import Bugsnag from "@bugsnag/js";
import { rejectAssignedContainer } from "../../../api/shippingApi";
import { showMessageWithTitle } from "../../../fns/message";
import Timer from "../../molecules/timer/TimerView";
import OrderLeftBtn from "./ContainerLeftBtn";
import OrderMapBtn from "./ContainerMapBtn";
import ContainerMiddleBtn from "./ContainerMiddleBtn";
import ReturnContainerDrawer from "../returnContainer/ReturnContainerDrawer";
import ReturnPhotoDrawer from "../returnPhoto/ReturnPhotoDrawer";
import {format} from "date-fns";

interface Props extends ContainerType {
  order_status?: "return" | "finished";
  onClaimDone: () => void;
  onUnclaimDone: () => void;
  onRefresh?: () => void;
  onPreview?: (uuid: string) => void;
  onMap?: (uuid: string) => void;
  calledFromMap?: boolean;
  currentPosition?: GeolocationPosition;
}
const ContainerItem = ({
  order_status,
  onClaimDone,
  onUnclaimDone,
  onPreview,
  onRefresh,
  onMap,
  currentPosition,
  ...order
}: Props) => {
  const { t } = useTranslation(["order"]);
  const returnRef = useRef<any>();
  const photoRef = useRef<any>();
  const timerRef = useRef<any>();
  let {
    count_box,
    price,
    count_shipping,
    count_return,
    count_total_items,
    duration,
    box,
    uuid,
    is_assigned,
    claim_expire,
    calledFromMap,
    unit_location,
    status,
    pickup_only,
    photo_return,
    container_class,
  } = order;
  const [distance, setDistance] = useState(0);
  let addressProps = "";
  let btnStyle = " primary ";
  let nameProps = {
    locationStyle: "black85",
    boxStyle: "",
    returnStyle: " errorActive-bg",
    containerStyle: "",
  };
  if (status === "UNIT_RETURNED" || status === "FINISHED") {
    btnStyle = " black85";
    addressProps = "black45";
    nameProps = {
      locationStyle: "black45",
      boxStyle: "black45-bg",
      returnStyle: " error-bg",
      containerStyle: "border-right",
    };
  } else if (status === "CLAIMED") {
    btnStyle = " error";
    nameProps = {
      locationStyle: "",
      boxStyle: "",
      returnStyle: " errorActive-bg",
      containerStyle: "border-right",
    };
  }
  useEffect(() => {
    const worker = new Worker("/workers/distance.worker.js", {
      type: "module",
    });
    worker.addEventListener("message", (e: any) => {
      setDistance(e.data.distance);
    });
    worker.addEventListener("error", (e) => {
      Bugsnag.notify(new Error("webworker distance " + e.message));
    });

    if (!!unit_location && currentPosition && currentPosition.coords) {
      worker.postMessage({
        payload: {
          unitPosition: { lat: unit_location.lat, lon: unit_location.lng },
          currentPosition: {
            lat: currentPosition?.coords?.latitude,
            lon: currentPosition?.coords?.longitude,
          },
        },
      });
    }
  }, [currentPosition]);

  const handleDeny = () => {
    rejectAssignedContainer(uuid, showDenyDone);
  };

  const handleComplete = (file: File) => {
    returnRef.current.show(file);
  };

  const handleShowHistoryPhoto = () => {
    photoRef.current.show();
  };

  const showDenyDone = () => {
    onUnclaimDone();
    showMessageWithTitle(
      t("popup.pickedup.return.title"),
      t("popup.deny.container.content")
    );
  };

  const claimedDate = useMemo(() => new Date(claim_expire), [claim_expire]);

  const bottomNode = (status: ShippingBoxStatus, btnStyle?: any) => (
    <div className={"border-top row order-item-bottom"}>
      <OrderLeftBtn
        {...order}
        order_status={order_status}
        is_assigned={is_assigned}
        onClaimDone={onClaimDone}
        onShowPhoto={handleShowHistoryPhoto}
        onUploadImage={handleComplete}
        onUnclaimDone={onUnclaimDone}
      />
      {is_assigned && (
        <Button
          type={"text"}
          className={"body bold flex order-item-btn errorActive"}
          onClick={handleDeny}
        >
          {t("btn.deny")}
        </Button>
      )}
      <ContainerMiddleBtn
        order_status={order_status}
        status={status}
        count_box={count_box}
        count_total_items={count_total_items}
        box={box}
        uuid={uuid}
        pickup_only={pickup_only}
        unit_name={unit_location?.name}
        onPreview={onPreview}
      />
      <div
        className={
          "body bold flex order-item-won " +
          (order_status === "finished" ? "black45" : "")
        }
      >
        {t("won", { won: price.toLocaleString() })}
      </div>
    </div>
  );

  const expireDateNode = (
    <div className={"order-duetime small bold "}>
      <Timer ref={timerRef} expireDate={claimedDate} onEnd={onRefresh}>
        {(second: number, minute: number) =>
          minute > 0 ? t("duetime", { minute }) : t("duesec", { second })
        }
      </Timer>
    </div>
  );

  const durationNode = (
    <div className={"small bold primary order-duration "}>
      {t("duration", {
        hour: Math.floor(duration / 60),
        minute: Math.ceil(duration % 60),
      })}
    </div>
  );

  const addressNode = (addressStyle: any) => (
    <div className={"column flex text-inside"}>
      <div className={"row "}>
        {pickup_only && (
          <div className={"order-return small bold"}>{t("return.address")}</div>
        )}
        <div className={"body1 bold " + addressStyle}>
          {unit_location?.address_road}
        </div>
      </div>
      <span className={"small black45"}>
        <span className={"bold"}>{t("addr.jibon")}</span>
        {"\u00a0" + unit_location?.address_jibun}
      </span>
      {status !== "DELIVERYCOMPLETED" &&
        status !== "UNIT_RETURNED" &&
        Number.isInteger(distance) && (
          <span className={"small order-item-address2"}>
            {t("to.hub")}
            <span className={"order-item-km"}>{t("km", { km: distance })}</span>
          </span>
        )}
    </div>
  );

  const nameTopNode = ({
    locationStyle,
    boxStyle,
    returnStyle,
    containerStyle,
  }: {
    locationStyle: string;
    boxStyle: string;
    returnStyle: string;
    containerStyle: string;
  }) => (
    <div className={"row text-inside " + containerStyle}>
      <div className={"body medium " + locationStyle}>
        {unit_location?.name}
      </div>
      <div className={"small bold order-item-box nowrap " + boxStyle}>
        {box?.alias}
      </div>
      {pickup_only ? (
        <div className={"small bold order-item-box nowrap " + returnStyle}>
          {t("contains.only.returns")}
        </div>
      ) : (
        count_shipping > 0 &&
        count_return > 0 && (
          <div
            className={
              boxStyle + " small bold order-item-box nowrap primary-bg"
            }
          >
            {t("contains.mixed")}
          </div>
        )
      )}
      <span className={"order-item-box small bold " + container_class}>
        {container_class}
      </span>
    </div>
  );

  return (
    <div className={"order-item-container"}>
      <div className={"order-item-top row"}>
        {nameTopNode(nameProps)}
        {status === "CLAIMED"
          ? expireDateNode
          : (status === "UNIT_RETURNED" || status === "DELIVERYCOMPLETED") &&
            durationNode}
      </div>
      <div className={"order-item-middle row border-top space-between"}>
        {addressNode(addressProps)}
        <OrderMapBtn
          calledFromMap={calledFromMap}
          uuid={uuid}
          status={status}
          alias={box?.alias}
          unit_location={unit_location}
          onMap={onMap}
        />
      </div>
      {bottomNode(status, btnStyle)}
      <ReturnContainerDrawer
        ref={returnRef}
        uuid={uuid}
        onReturned={onClaimDone}
      />
      <ReturnPhotoDrawer ref={photoRef} image={photo_return} />
    </div>
  );
};

export default React.memo(ContainerItem);
